import { navigate } from 'gatsby-link';
import React from 'react';

export default function Home() {
  React.useEffect(() => {
    navigate(`/login${window.location.search}`);
  }, []);

  return null;
}
